import Resource from "./resource.js";
export class Organizations extends Resource {
    /**
     * Organizations
     */
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
    find = this.makeRequest({
        method: "GET",
        path: "/organizations",
    });
    create = this.makeRequest({
        method: "POST",
        path: "/organizations",
        returnResourceIdInLocationHeader: { field: "id" },
    });
    delById = this.makeRequest({
        method: "DELETE",
        path: "/organizations/{id}",
        urlParamKeys: ["id"],
    });
    updateById = this.makeUpdateRequest({
        method: "PUT",
        path: "/organizations/{id}",
        urlParamKeys: ["id"],
    });
}
