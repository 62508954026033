import { jsxs as y, jsx as n, Fragment as D } from "react/jsx-runtime";
import { AlertGroup as Ne, Alert as De, AlertVariant as L, AlertActionCloseButton as qe, Page as Le, Modal as ye, ModalVariant as _e, Button as x, TextContent as Be, Text as B, TextVariants as Ue, Spinner as Me, FormHelperText as W, HelperText as j, HelperTextItem as z, Popover as He, Icon as U, FormGroup as ve, NumberInput as $e, ValidatedOptions as w, InputGroup as J, InputGroupItem as M, TextInput as q, Switch as Ge, TextArea as be, Title as Ke, Card as We, CardHeader as je, CardTitle as ze, CardBody as Je, Grid as Ye, GridItem as re, PageSection as Qe, JumpLinks as Xe, JumpLinksItem as Ze, ButtonVariant as le, Checkbox as et, Radio as tt, Brand as nt, Avatar as rt } from "@patternfly/react-core";
import { createContext as Y, useContext as Q, useState as k, useCallback as Ie, useEffect as X, useMemo as S, useRef as lt, forwardRef as at, useId as st, Fragment as Ce } from "react";
import { useTranslation as Z } from "react-i18next";
import it from "keycloak-js";
import { ExclamationCircleIcon as ot, HelpIcon as ae, EyeIcon as ct, EyeSlashIcon as dt, CubeIcon as ut, PaypalIcon as mt, InstagramIcon as ht, BitbucketIcon as ft, MicrosoftIcon as gt, TwitterIcon as pt, StackOverflowIcon as yt, OpenshiftIcon as vt, LinkedinIcon as bt, GoogleIcon as It, GitlabIcon as Ct, FacebookSquareIcon as Tt, GithubIcon as wt, MinusCircleIcon as kt, PlusCircleIcon as xt } from "@patternfly/react-icons";
import { useFormContext as ee, Controller as F, useController as te, FormProvider as St, useWatch as At } from "react-hook-form";
import { Select as Te, SelectVariant as se, SelectOption as we, Dropdown as Rt, KebabToggle as Et, DropdownToggle as Vt, DropdownItem as ie, PageHeader as Ft, PageHeaderTools as Ot, PageHeaderToolsGroup as Pt, PageHeaderToolsItem as _ } from "@patternfly/react-core/deprecated";
import { get as Nt } from "lodash-es";
import N from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as Dt } from "@patternfly/react-styles";
import './main.css';const ke = Y(void 0), Nn = () => Q(ke), qt = ({ children: e }) => {
  const [t, r] = k([]), l = (i) => {
    r((o) => o.filter((c) => c.id !== i));
  }, a = (i, o = L.success, c) => {
    r([
      {
        id: Math.random() * 100,
        message: i,
        variant: o,
        description: c
      },
      ...t
    ]);
  }, s = (i) => {
    a(i, L.danger);
  };
  return /* @__PURE__ */ y(ke.Provider, { value: { addAlert: a, addError: s }, children: [
    /* @__PURE__ */ n(Ne, { isToast: !0, "data-testid": "alerts", children: t.map(({ id: i, variant: o, message: c, description: d }) => /* @__PURE__ */ n(
      De,
      {
        isLiveRegion: !0,
        variant: L[o],
        variantLabel: "",
        title: c,
        actionClose: /* @__PURE__ */ n(
          qe,
          {
            title: c,
            onClose: () => l(i)
          }
        ),
        timeout: !0,
        onTimeout: () => l(i),
        children: d && /* @__PURE__ */ n("p", { children: d })
      },
      i
    )) }),
    e
  ] });
}, Lt = (e) => {
  const { t } = Z(), r = e.error, l = _t(r);
  function a() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ n(Le, { children: /* @__PURE__ */ n(
    ye,
    {
      variant: _e.small,
      title: t("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ n(x, { variant: "primary", onClick: a, children: t("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ y(Be, { children: [
        /* @__PURE__ */ n(B, { children: t("somethingWentWrongDescription") }),
        l && /* @__PURE__ */ n(B, { component: Ue.small, children: l })
      ] })
    }
  ) });
};
function _t(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function Bt(e, t) {
  const r = Y(t);
  return r.displayName = e, r;
}
function Ut(e) {
  return e != null;
}
function Mt(e) {
  const t = Q(e);
  if (Ut(t))
    return t;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function Ht(e, t, r) {
  const [l, a] = k(
    () => e.getItem(t) ?? r
  ), s = Ie((i) => {
    a(i), e.setItem(t, i);
  }, []);
  return X(() => {
    a(e.getItem(t) ?? r), window.addEventListener("storage", i);
    function i(o) {
      o.storageArea === e && (o.key === null || o.key === t) && a(o.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, t]), [l, s];
}
function $t(e, t, r) {
  const l = S(
    () => JSON.stringify(r),
    [r]
  ), [a, s] = Ht(
    e,
    t,
    l
  ), i = S(() => JSON.parse(a), [a]), o = Ie(
    (c) => s(JSON.stringify(c)),
    []
  );
  return [i, o];
}
const xe = Bt(
  "HelpContext",
  void 0
), Gt = () => Mt(xe), Kt = ({ children: e }) => {
  const [t, r] = $t(localStorage, "helpEnabled", !0);
  function l() {
    r(!t);
  }
  return /* @__PURE__ */ n(xe.Provider, { value: { enabled: t, toggleHelp: l }, children: e });
}, Wt = () => Y(void 0);
let H;
const Dn = () => {
  const e = Q(H);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, qn = ({
  environment: e,
  children: t
}) => {
  H = Wt();
  const r = lt(!1), [l, a] = k(!1), [s, i] = k(), o = S(() => {
    const c = new it({
      url: e.authUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return c.onAuthLogout = () => c.login(), c;
  }, [e]);
  return X(() => {
    if (r.current)
      return;
    o.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => a(!0)).catch((d) => i(d)), r.current = !0;
  }, [o]), s ? /* @__PURE__ */ n(Lt, { error: s }) : l ? /* @__PURE__ */ n(H.Provider, { value: { environment: e, keycloak: o }, children: /* @__PURE__ */ n(qt, { children: /* @__PURE__ */ n(Kt, { children: t }) }) }) : /* @__PURE__ */ n(Me, {});
}, oe = "master", ce = new URLSearchParams(window.location.search).get("realm") || location.pathname.match("/realms/(.*?)/account")?.[1], jt = {
  authUrl: "http://localhost:8180",
  authServerUrl: "http://localhost:8180",
  baseUrl: `http://localhost:8180/realms/${ce ?? oe}/account/`,
  realm: ce ?? oe,
  clientId: "security-admin-console-v2",
  resourceUrl: "http://localhost:8080",
  logo: "/logo.svg",
  logoUrl: "/",
  locale: "en",
  consoleBaseUrl: "/admin/master/console/",
  masterRealm: "master",
  resourceVersion: "unknown",
  features: {
    isRegistrationEmailAsUsername: !1,
    isEditUserNameAllowed: !0,
    isInternationalizationEnabled: !0,
    isLinkedAccountsEnabled: !0,
    isEventsEnabled: !0,
    isMyResourcesEnabled: !0,
    isTotpConfigured: !0,
    deleteAccountAllowed: !0,
    updateEmailFeatureEnabled: !0,
    updateEmailActionEnabled: !0,
    isViewGroupsEnabled: !0,
    isOid4VciEnabled: !1
  }
}, Ln = {
  ...jt,
  ...zt()
};
function zt() {
  const e = document.getElementById("environment");
  let t = {};
  try {
    e?.textContent && (t = JSON.parse(e.textContent));
  } catch {
    console.error("Unable to parse environment variables.");
  }
  return t;
}
const _n = ({
  modalTitle: e,
  continueLabel: t,
  cancelLabel: r,
  buttonTitle: l,
  isDisabled: a,
  buttonVariant: s,
  buttonTestRole: i,
  onContinue: o,
  component: c = x,
  children: d,
  ...u
}) => {
  const [p, h] = k(!1);
  return /* @__PURE__ */ y(D, { children: [
    /* @__PURE__ */ n(
      c,
      {
        variant: s,
        onClick: () => h(!0),
        isDisabled: a,
        "data-testrole": i,
        children: l
      }
    ),
    /* @__PURE__ */ n(
      ye,
      {
        variant: "small",
        ...u,
        title: e,
        isOpen: p,
        onClose: () => h(!1),
        actions: [
          /* @__PURE__ */ n(
            x,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                h(!1), o();
              },
              children: t
            },
            "confirm"
          ),
          /* @__PURE__ */ n(
            x,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => h(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: d
      }
    )
  ] });
}, Se = ({ message: e, ...t }) => /* @__PURE__ */ n(W, { ...t, children: /* @__PURE__ */ n(j, { children: /* @__PURE__ */ n(z, { icon: /* @__PURE__ */ n(ot, {}), variant: "error", children: e }) }) }), Ae = ({
  helpText: e,
  fieldLabelId: t,
  noVerticalAlign: r = !0,
  unWrap: l = !1
}) => {
  const { enabled: a } = Gt();
  return a ? /* @__PURE__ */ n(He, { bodyContent: e, children: /* @__PURE__ */ y(D, { children: [
    !l && /* @__PURE__ */ n(
      "button",
      {
        "data-testid": `help-label-${t}`,
        "aria-label": t,
        onClick: (s) => s.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ n(U, { isInline: r, children: /* @__PURE__ */ n(ae, {}) })
      }
    ),
    l && /* @__PURE__ */ n(U, { isInline: r, children: /* @__PURE__ */ n(ae, {}) })
  ] }) }) : null;
}, E = ({
  name: e,
  label: t,
  labelIcon: r,
  error: l,
  children: a,
  ...s
}) => /* @__PURE__ */ y(
  ve,
  {
    label: t || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ n(Ae, { helpText: r, fieldLabelId: e }) : void 0,
    ...s,
    children: [
      a,
      l && /* @__PURE__ */ n(Se, { "data-testid": `${e}-helper`, message: l.message })
    ]
  }
), Bn = ({
  name: e,
  label: t,
  controller: r,
  labelIcon: l,
  ...a
}) => {
  const {
    control: s,
    formState: { errors: i }
  } = ee();
  return /* @__PURE__ */ n(
    E,
    {
      name: e,
      label: t,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: l,
      children: /* @__PURE__ */ n(
        F,
        {
          ...r,
          name: e,
          control: s,
          render: ({ field: o }) => {
            const c = !!r.rules?.required, d = r.rules?.min, u = o.value === 0 ? r.defaultValue : o.value, p = (h) => o.onChange(d ? Math.max(h, Number(d)) : h);
            return /* @__PURE__ */ n(
              $e,
              {
                ...a,
                id: e,
                value: u,
                validated: i[e] ? w.error : w.default,
                required: c,
                min: Number(d),
                max: Number(r.rules?.max),
                onPlus: () => p(u + 1),
                onMinus: () => p(u - 1),
                onChange: (h) => {
                  const m = Number(h.currentTarget.value);
                  p(isNaN(m) ? r.defaultValue : m);
                }
              }
            );
          }
        }
      )
    }
  );
}, Jt = ({
  hasReveal: e = !0,
  innerRef: t,
  ...r
}) => {
  const { t: l } = Z(), [a, s] = k(!0);
  return /* @__PURE__ */ y(J, { children: [
    /* @__PURE__ */ n(M, { children: /* @__PURE__ */ n(
      q,
      {
        ...r,
        type: a ? "password" : "text",
        ref: t
      }
    ) }),
    e && /* @__PURE__ */ n(
      x,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => s(!a),
        children: a ? /* @__PURE__ */ n(ct, {}) : /* @__PURE__ */ n(dt, {})
      }
    )
  ] });
}, Re = at(
  (e, t) => /* @__PURE__ */ n(Jt, { ...e, innerRef: t })
);
Re.displayName = "PasswordInput";
const Un = (e) => {
  const { labelIcon: t, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: i } = te({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ y(
    E,
    {
      name: e.name,
      label: e.label,
      labelIcon: t,
      isRequired: l,
      error: i.error,
      children: [
        /* @__PURE__ */ n(
          Re,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? w.error : w.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ n(W, { children: /* @__PURE__ */ n(j, { children: /* @__PURE__ */ n(z, { children: e.helperText }) }) })
      ]
    }
  );
}, $ = (e) => typeof e == "string", de = (e) => $(e) ? e : e.key, Yt = ({
  name: e,
  label: t,
  options: r,
  controller: l,
  variant: a = se.single,
  labelIcon: s,
  onFilter: i,
  ...o
}) => {
  const {
    control: c,
    formState: { errors: d }
  } = ee(), [u, p] = k(!1), h = (f = "") => {
    const v = f.toLowerCase();
    return r.filter(
      (g) => ($(g) ? g : g.value).toLowerCase().startsWith(v)
    ).map((g) => /* @__PURE__ */ n(we, { value: de(g), children: $(g) ? g : g.value }, de(g)));
  }, m = (f) => typeof f[0] != "string";
  return /* @__PURE__ */ n(
    E,
    {
      name: e,
      label: t,
      isRequired: !!l.rules?.required,
      error: d[e],
      labelIcon: s,
      children: /* @__PURE__ */ n(
        F,
        {
          ...l,
          name: e,
          control: c,
          render: ({ field: { onChange: f, value: v } }) => /* @__PURE__ */ n(
            Te,
            {
              ...o,
              toggleId: e.slice(e.lastIndexOf(".") + 1),
              onToggle: (g, C) => p(C),
              selections: m(r) ? r.filter(
                (g) => Array.isArray(v) ? v.includes(g.key) : v === g.key
              ).map((g) => g.value) : v,
              onSelect: (g, C) => {
                if (g.stopPropagation(), a.includes("multi") && Array.isArray(v)) {
                  const V = C.toString(), I = m(r) ? r.find((b) => b.value === V)?.key : V;
                  v.includes(I) ? f(v.filter((b) => b !== I)) : f([...v, V]);
                } else
                  f(C), p(!1);
              },
              onClear: a !== se.single ? (g) => {
                g.stopPropagation(), f([]);
              } : void 0,
              onFilter: (g, C) => (i?.(C), h(C)),
              isOpen: u,
              variant: a,
              validated: d[e] ? w.error : w.default,
              children: h()
            }
          )
        }
      )
    }
  );
}, Mn = ({
  labelOn: e,
  stringify: t,
  defaultValue: r,
  labelIcon: l,
  ...a
}) => {
  const i = r ?? (t ? "false" : !1), { control: o } = ee();
  return /* @__PURE__ */ n(
    E,
    {
      hasNoPaddingTop: !0,
      name: a.name,
      isRequired: a.rules?.required === !0,
      label: a.label,
      labelIcon: l,
      children: /* @__PURE__ */ n(
        F,
        {
          control: o,
          name: a.name,
          defaultValue: i,
          render: ({ field: { onChange: c, value: d } }) => /* @__PURE__ */ n(
            Ge,
            {
              ...a,
              id: a.name,
              "data-testid": a.name,
              label: e,
              isChecked: t ? d === "true" : d,
              onChange: (u, p) => {
                const h = t ? p.toString() : p;
                a.onChange?.(u, p), c(h);
              }
            }
          )
        }
      )
    }
  );
}, Hn = (e) => {
  const t = !!e.rules?.required, r = e.defaultValue ?? "", { field: l, fieldState: a } = te({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ n(
    E,
    {
      isRequired: t,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: a.error,
      children: /* @__PURE__ */ n(
        be,
        {
          isRequired: t,
          id: e.name,
          "data-testid": e.name,
          validated: a.error ? w.error : w.default,
          isDisabled: e.isDisabled,
          ...l
        }
      )
    }
  );
}, $n = (e) => {
  const { labelIcon: t, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: i } = te({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ y(
    E,
    {
      name: e.name,
      label: e.label,
      labelIcon: t,
      isRequired: l,
      error: i.error,
      children: [
        /* @__PURE__ */ n(
          q,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? w.error : w.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ n(W, { children: /* @__PURE__ */ n(j, { children: /* @__PURE__ */ n(z, { children: e.helperText }) }) })
      ]
    }
  );
}, Qt = be, Gn = ({ icon: e }) => {
  const t = Xt(e);
  return /* @__PURE__ */ n(U, { size: "lg", children: /* @__PURE__ */ n(t, { alt: e }) });
};
function Xt(e) {
  switch (e) {
    case "github":
      return wt;
    case "facebook":
      return Tt;
    case "gitlab":
      return Ct;
    case "google":
      return It;
    case "linkedin":
    case "linkedin-openid-connect":
      return bt;
    case "openshift-v3":
    case "openshift-v4":
      return vt;
    case "stackoverflow":
      return yt;
    case "twitter":
      return pt;
    case "microsoft":
      return gt;
    case "bitbucket":
      return ft;
    case "instagram":
      return ht;
    case "paypal":
      return mt;
    default:
      return ut;
  }
}
const Zt = "_title_180i0_2", en = {
  title: Zt
}, Ee = ({
  id: e,
  title: t,
  headingLevel: r = "h1",
  size: l = "xl",
  ...a
}) => /* @__PURE__ */ n(
  Ke,
  {
    headingLevel: r,
    size: l,
    className: en.title,
    id: e,
    tabIndex: 0,
    ...a,
    children: t
  }
), tn = ({
  title: e,
  children: t,
  scrollId: r,
  className: l
}) => {
  const a = st();
  return /* @__PURE__ */ y(We, { id: a, className: l, isFlat: !0, children: [
    /* @__PURE__ */ n(je, { className: "kc-form-panel__header", children: /* @__PURE__ */ n(ze, { tabIndex: 0, children: /* @__PURE__ */ n(Ee, { id: r, title: e }) }) }),
    /* @__PURE__ */ n(Je, { className: "kc-form-panel__body", children: t })
  ] });
}, nn = (e) => {
  const { title: t, children: r, scrollId: l, ...a } = e;
  return /* @__PURE__ */ n("section", { ...a, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ y(D, { children: [
    /* @__PURE__ */ n(Ee, { id: l, title: t }),
    r
  ] }) });
}, rn = "_panel_1cdve_1", ln = "_sticky_1cdve_5", ue = {
  panel: rn,
  sticky: ln
}, an = "kc-main-content-page-container", me = (e) => e.replace(/\s+/g, "-"), sn = ({
  label: e,
  sections: t,
  borders: r = !1,
  ...l
}) => {
  const a = S(
    () => t.filter(({ isHidden: s }) => !s),
    [t]
  );
  return /* @__PURE__ */ y(Ye, { hasGutter: !0, ...l, children: [
    /* @__PURE__ */ n(re, { md: 8, sm: 12, children: a.map(({ title: s, panel: i }) => {
      const o = me(s.toLowerCase());
      return /* @__PURE__ */ n(Ce, { children: r ? /* @__PURE__ */ n(
        tn,
        {
          scrollId: o,
          title: s,
          className: ue.panel,
          children: i
        }
      ) : /* @__PURE__ */ n(nn, { scrollId: o, title: s, children: i }) }, s);
    }) }),
    /* @__PURE__ */ n(re, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ n(Qe, { className: ue.sticky, children: /* @__PURE__ */ n(
      Xe,
      {
        isVertical: !0,
        scrollableSelector: `#${an}`,
        label: e,
        offset: 100,
        children: a.map(({ title: s }) => {
          const i = me(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ n(
              Ze,
              {
                href: `#${i}`,
                "data-testid": `jump-link-${i}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
}, on = (e, t, r) => (e.isValid || r) && (e.isDirty || t) && !e.isLoading && !e.isValidating && !e.isSubmitting, Kn = ({
  formState: e,
  isDisabled: t = !1,
  allowInvalid: r = !1,
  allowNonDirty: l = !1,
  children: a,
  ...s
}) => /* @__PURE__ */ n(
  x,
  {
    variant: "primary",
    isDisabled: e && !on(e, l, r) || t,
    ...s,
    type: "submit",
    children: a
  }
), cn = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, dn = ({
  t: e,
  form: t,
  supportedLocales: r,
  currentLocale: l
}) => {
  const a = S(
    () => r.map((s) => ({
      key: s,
      value: e(`locale_${s}`, cn(s) ?? s)
    })).sort((s, i) => s.value.localeCompare(i.value, l)),
    [r, l, e]
  );
  return a.length ? /* @__PURE__ */ n(St, { ...t, children: /* @__PURE__ */ n(
    Yt,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a,
      variant: a.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, Ve = (e) => e?.includes("${"), Fe = (e) => e.substring(2, e.length - 1), R = (e, t, r) => (Ve(t) ? e(Fe(t)) : t) || r, G = (e, t) => R(e, t.displayName, t.name), un = ["username", "firstName", "lastName", "email"], Oe = (e) => e && un.includes(e), A = (e) => `${Oe(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Wn = (e) => e.replaceAll(".", "🍺"), jn = (e) => e.replaceAll("🍺", ".");
function zn(e, t, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((l) => {
    const a = Object.assign(
      {},
      l.params?.map((s) => r(Ve(s.toString()) ? Fe(s) : s))
    );
    t(A(l.field), {
      message: r(l.errorMessage, {
        ...a,
        defaultValue: l.errorMessage || l.field
      }),
      type: "server"
    });
  });
}
function O({
  required: e,
  validators: t
}) {
  return e || mn(t);
}
function mn(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Jn(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: t } = e;
  return he(t) ? !0 : typeof t != "object" || t === null || !("errors" in t) || !Array.isArray(t.errors) ? !1 : t.errors.every(he);
}
function he(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const P = ({
  t: e,
  form: t,
  attribute: r,
  renderer: l,
  children: a
}) => {
  const s = R(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = t, o = l?.(r), c = Nt(i, A(r.name));
  return /* @__PURE__ */ y(
    ve,
    {
      label: G(e, r) || "",
      fieldId: r.name,
      isRequired: O(r),
      labelIcon: s ? /* @__PURE__ */ n(Ae, { helpText: s, fieldLabelId: r.name }) : void 0,
      children: [
        o ? /* @__PURE__ */ y(J, { children: [
          a,
          o
        ] }) : a,
        c && /* @__PURE__ */ n(
          Se,
          {
            "data-testid": `${r.name}-helper`,
            message: c.message
          }
        )
      ]
    },
    r.name
  );
}, hn = ({
  t: e,
  form: t,
  attribute: r,
  renderer: l,
  ...a
}) => /* @__PURE__ */ n(P, { t: e, form: t, attribute: r, renderer: l, children: /* @__PURE__ */ n(
  fn,
  {
    t: e,
    form: t,
    "aria-label": G(e, r),
    name: A(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: G(e, r)
    }),
    ...a
  }
) }), fn = ({
  t: e,
  name: t,
  inputType: r,
  form: l,
  addButtonLabel: a,
  isDisabled: s = !1,
  defaultValue: i,
  id: o,
  ...c
}) => {
  const { register: d, setValue: u, control: p } = l, h = At({
    name: t,
    control: p,
    defaultValue: i || ""
  }), m = S(() => Array.isArray(h) && h.length !== 0 ? h : i || [""], [h]), f = (I) => {
    C([...m.slice(0, I), ...m.slice(I + 1)]);
  }, v = () => {
    C([...m, ""]);
  }, g = (I, b) => {
    C([...m.slice(0, I), b, ...m.slice(I + 1)]);
  }, C = (I) => {
    const b = I.flatMap((ne) => ne);
    u(t, b, {
      shouldDirty: !0
    });
  }, V = r.startsWith("html") ? r.substring(6) : "text";
  return X(() => {
    d(t);
  }, [d]), /* @__PURE__ */ n("div", { id: o, children: m.map((I, b) => /* @__PURE__ */ y(Ce, { children: [
    /* @__PURE__ */ y(J, { children: [
      /* @__PURE__ */ n(M, { isFill: !0, children: /* @__PURE__ */ n(
        q,
        {
          "data-testid": t + b,
          onChange: (ne, Pe) => g(b, Pe),
          name: `${t}.${b}.value`,
          value: I,
          isDisabled: s,
          type: V,
          ...c
        }
      ) }),
      /* @__PURE__ */ n(M, { children: /* @__PURE__ */ n(
        x,
        {
          "data-testid": "remove" + b,
          variant: le.link,
          onClick: () => f(b),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: m.length === 1 || s,
          children: /* @__PURE__ */ n(kt, {})
        }
      ) })
    ] }),
    b === m.length - 1 && /* @__PURE__ */ y(
      x,
      {
        variant: le.link,
        onClick: v,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !I || s,
        children: [
          /* @__PURE__ */ n(xt, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, b)) });
}, fe = (e) => {
  const { form: t, inputType: r, attribute: l } = e, a = O(l), s = r.startsWith("multiselect"), i = s ? et : tt, o = l.validators?.options?.options || [], c = l.annotations?.inputOptionLabels || {};
  return /* @__PURE__ */ n(P, { ...e, children: /* @__PURE__ */ n(
    F,
    {
      name: A(l.name),
      control: t.control,
      defaultValue: "",
      render: ({ field: d }) => /* @__PURE__ */ n(D, { children: o.map((u) => /* @__PURE__ */ n(
        i,
        {
          id: u,
          "data-testid": u,
          label: R(e.t, c[u], u),
          value: u,
          isChecked: d.value.includes(u),
          onChange: () => {
            s ? d.value.includes(u) ? d.onChange(
              d.value.filter((p) => p !== u)
            ) : d.onChange([...d.value, u]) : d.onChange([u]);
          },
          readOnly: l.readOnly,
          isRequired: a
        },
        u
      )) })
    }
  ) });
}, ge = (e) => {
  const { t, form: r, inputType: l, attribute: a } = e, [s, i] = k(!1), o = O(a), c = l === "multiselect", d = (m, f) => {
    c ? f.value.includes(m) ? f.onChange(f.value.filter((v) => v !== m)) : f.onChange([...f.value, m]) : f.onChange(m);
  }, u = a.validators?.options?.options || [], p = a.annotations?.inputOptionLabels || {}, h = (m) => R(e.t, p[m], m);
  return /* @__PURE__ */ n(P, { ...e, children: /* @__PURE__ */ n(
    F,
    {
      name: A(a.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: m }) => /* @__PURE__ */ n(
        Te,
        {
          toggleId: a.name,
          onToggle: (f, v) => i(v),
          onSelect: (f, v) => {
            const g = v.toString();
            d(g, m), Array.isArray(m.value) || i(!1);
          },
          selections: m.value ? m.value : c ? [] : t("choose"),
          variant: c ? "typeaheadmulti" : u.length >= 10 ? "typeahead" : "single",
          "aria-label": t("selectOne"),
          isOpen: s,
          isDisabled: a.readOnly,
          required: o,
          children: ["", ...u].map((f) => /* @__PURE__ */ n(
            we,
            {
              selected: m.value === f,
              value: f,
              children: f ? h(f) : t("choose")
            },
            f
          ))
        }
      )
    }
  ) });
}, gn = (e) => {
  const { form: t, attribute: r } = e, l = O(r);
  return /* @__PURE__ */ n(P, { ...e, children: /* @__PURE__ */ n(
    Qt,
    {
      id: r.name,
      "data-testid": r.name,
      ...t.register(A(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: l
    }
  ) });
}, T = (e) => {
  const { form: t, inputType: r, attribute: l } = e, a = O(l), s = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ n(P, { ...e, children: /* @__PURE__ */ n(
    q,
    {
      id: l.name,
      "data-testid": l.name,
      type: s,
      placeholder: R(
        e.t,
        l.annotations?.inputTypePlaceholder
      ),
      readOnly: l.readOnly,
      isRequired: a,
      ...t.register(A(l.name))
    }
  ) });
}, K = {
  text: T,
  textarea: gn,
  select: ge,
  "select-radiobuttons": fe,
  multiselect: ge,
  "multiselect-checkboxes": fe,
  "html5-email": T,
  "html5-tel": T,
  "html5-url": T,
  "html5-number": T,
  "html5-range": T,
  "html5-datetime-local": T,
  "html5-date": T,
  "html5-month": T,
  "html5-time": T,
  "multi-input": hn
}, Yn = ({
  t: e,
  form: t,
  userProfileMetadata: r,
  supportedLocales: l,
  currentLocale: a,
  hideReadOnly: s = !1,
  renderer: i
}) => {
  const o = S(() => {
    if (!r.attributes)
      return [];
    const c = s ? r.attributes.filter(({ readOnly: d }) => !d) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((d) => ({
      group: d,
      attributes: c.filter(
        (u) => u.group === d.name
      )
    }));
  }, [
    s,
    r.groups,
    r.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ n(
    sn,
    {
      label: e("jumpToSection"),
      sections: o.filter((c) => c.attributes.length > 0).map(({ group: c, attributes: d }) => ({
        title: R(e, c.displayHeader, c.name) || e("general"),
        panel: /* @__PURE__ */ y("div", { className: "pf-v5-c-form", children: [
          c.displayDescription && /* @__PURE__ */ n(B, { className: "pf-v5-u-pb-lg", children: R(e, c.displayDescription, "") }),
          d.map((u) => /* @__PURE__ */ n(
            pn,
            {
              t: e,
              form: t,
              supportedLocales: l,
              currentLocale: a,
              renderer: i,
              attribute: u
            },
            u.name
          ))
        ] })
      }))
    }
  );
}, pn = ({
  t: e,
  form: t,
  renderer: r,
  supportedLocales: l,
  currentLocale: a,
  attribute: s
}) => {
  const i = t.watch(
    A(s.name)
  ), o = S(() => vn(s), [s]), c = s.multivalued || In(i) ? K["multi-input"] : K[o];
  return s.name === "locale" ? /* @__PURE__ */ n(
    dn,
    {
      form: t,
      supportedLocales: l,
      currentLocale: a,
      t: e,
      attribute: s
    }
  ) : /* @__PURE__ */ n(
    c,
    {
      t: e,
      form: t,
      inputType: o,
      attribute: s,
      renderer: r
    }
  );
}, yn = "text";
function vn(e) {
  if (Oe(e.name))
    return "text";
  const t = e.annotations?.inputType;
  return bn(t) ? t : yn;
}
const bn = (e) => typeof e == "string" && e in K, In = (e) => Array.isArray(e) && e.length > 1, Cn = ({
  className: e = "",
  border: t,
  size: r = "md"
}) => /* @__PURE__ */ y(
  "svg",
  {
    className: Dt(
      N.avatar,
      N.modifiers[r],
      t === "light" && N.modifiers.light,
      t === "dark" && N.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ n(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ n("defs", { children: /* @__PURE__ */ n(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ n("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ n(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ n("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ n(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ n("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ y("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ n(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ n(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ n(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ n(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ n(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), pe = ({
  isKebab: e = !1,
  title: t,
  dropDownItems: r,
  ...l
}) => {
  const [a, s] = k(!1);
  return /* @__PURE__ */ n(
    Rt,
    {
      ...l,
      isPlain: !0,
      position: "right",
      toggle: e ? /* @__PURE__ */ n(Et, { onToggle: (i, o) => s(o), children: t }) : /* @__PURE__ */ n(Vt, { onToggle: (i, o) => s(o), children: t }),
      isOpen: a,
      dropdownItems: r
    }
  );
};
function Tn(e, t) {
  if (!e)
    return t("unknownUser");
  const r = e.given_name, l = e.family_name, a = e.preferred_username;
  return r && l ? t("fullName", { givenName: r, familyName: l }) : r || l || a || t("unknownUser");
}
const Qn = ({
  keycloak: e,
  brand: { href: t, ...r },
  avatar: l,
  features: {
    hasLogout: a = !0,
    hasManageAccount: s = !0,
    hasUsername: i = !0
  } = {},
  kebabDropdownItems: o,
  dropdownItems: c = [],
  toolbarItems: d,
  ...u
}) => {
  const { t: p } = Z(), h = [];
  s && h.push(
    /* @__PURE__ */ n(
      ie,
      {
        onClick: () => e.accountManagement(),
        children: p("manageAccount")
      },
      "manageAccount"
    )
  ), a && h.push(
    /* @__PURE__ */ n(ie, { onClick: () => e.logout(), children: p("signOut") }, "signOut")
  );
  const m = e.idTokenParsed?.picture;
  return /* @__PURE__ */ n(
    Ft,
    {
      ...u,
      logo: /* @__PURE__ */ n(nt, { ...r }),
      logoProps: { href: t },
      headerTools: /* @__PURE__ */ y(Ot, { children: [
        /* @__PURE__ */ y(Pt, { children: [
          /* @__PURE__ */ n(
            _,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ n(
                pe,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...o || c,
                    h
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ n(_, { children: d }),
          /* @__PURE__ */ n(
            _,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ n(
                pe,
                {
                  "data-testid": "options",
                  dropDownItems: [...c, h],
                  title: i ? Tn(e.idTokenParsed, p) : void 0
                }
              )
            }
          )
        ] }),
        m || l?.src ? /* @__PURE__ */ n(rt, { src: m, alt: p("avatar"), ...l }) : /* @__PURE__ */ n(Cn, { ...l })
      ] })
    }
  );
};
export {
  qt as AlertProvider,
  _n as ContinueCancelModal,
  Lt as ErrorPage,
  Se as FormErrorText,
  tn as FormPanel,
  Kn as FormSubmitButton,
  Kt as Help,
  Ae as HelpItem,
  Gn as IconMapper,
  Qn as KeycloakMasthead,
  qn as KeycloakProvider,
  Qt as KeycloakTextArea,
  Bn as NumberControl,
  Un as PasswordControl,
  Re as PasswordInput,
  sn as ScrollForm,
  Yt as SelectControl,
  Mn as SwitchControl,
  Hn as TextAreaControl,
  $n as TextControl,
  Yn as UserProfileFields,
  Wn as beerify,
  Bt as createNamedContext,
  jn as debeerify,
  Ln as environment,
  Ut as isDefined,
  Jn as isUserProfileError,
  R as label,
  an as mainPageContentId,
  zn as setUserProfileServerError,
  Nn as useAlerts,
  Dn as useEnvironment,
  Gt as useHelp,
  Mt as useRequiredContext,
  $t as useStoredState
};
